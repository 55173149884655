import { Divider } from 'antd';
import React from 'react';

export interface FormDividerProps {
  text: string;
  fontSize: number;
  id?: string;
  required?: boolean;
  width?: number;
}

const FormDividerTitle = ({
  text,
  fontSize = 30,
  id = '',
  required = false,
  width = 65,
}: FormDividerProps) => {
  return (
    <div className="form-title-wrapper" style={{ width: `${width}%` }}>
      <div id={id} className={'form-divider-anchor'} />
      <Divider className={`form-divider-title`} />
      <div
        className="form-title-text ant-form-item-label"
        style={{ fontSize: fontSize + 'px' }}
      >
        <label
          className={`ant-form-item-no-colon ${
            required ? 'ant-form-item-required' : ''
          }`}
          style={{ fontSize: fontSize + 'px' }}
        >
          {text}
        </label>
      </div>
    </div>
  );
};

export default FormDividerTitle;
