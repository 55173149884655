import { KEYCLOAK_HOST, KEYCLOAK_REALM } from '@/config';
import { sbaas_stcms_api } from '@/config/sbaas';
import KeycloakAdminClient from '@keycloak/keycloak-admin-client';
import { ConnectionConfig } from '@keycloak/keycloak-admin-client/lib/client';
import ProtocolMapperRepresentation from '@keycloak/keycloak-admin-client/lib/defs/protocolMapperRepresentation';
// import UserRepresentation from 'keycloak-admin/lib/defs/userRepresentation';

class KeycloakRequirement {
  config: ConnectionConfig;
  constructor(url: string, realmName: string) {
    this.config = {
      baseUrl: url,
      realmName: realmName,
    };
  }
}

class KeycloakAdmin {
  kcAdmin: KeycloakAdminClient;
  constructor(config: KeycloakRequirement) {
    this.kcAdmin = new KeycloakAdminClient(config.config);
  }

  async setKcAdminToken() {
    let result = false;
    let aToken;
    try {
      const api = await sbaas_stcms_api.script('kc_token').get();
      aToken = api.data;
    } catch (err) {
      throw new Error('Server was Busy');
    }
    if (aToken.access_token) {
      this.kcAdmin.setAccessToken(aToken.access_token);
      result = true;
    }
    return result;
  }

  async getUserByEmail(email: string) {
    await this.setKcAdminToken();
    const result = await this.kcAdmin.users.find({
      email: email,
    });
    return result;
  }

  async getUserById(id: string) {
    await this.setKcAdminToken();
    const result = await this.kcAdmin.users.findOne({
      id: id,
    });
    return result;
  }

  async getRealmRoles(id: string) {
    await this.setKcAdminToken();
    const result = await this.kcAdmin.users.listRealmRoleMappings({ id: id });
    return result;
  }

  async editRegisterUser(id: any, user?: any) {
    await this.setKcAdminToken();
    if (user) {
    }
    const res = await this.kcAdmin.users.update(
      {
        id: id,
      },
      user,
    );
    return res;
  }

  async addMultipleMapper(mapper: ProtocolMapperRepresentation[]) {
    await this.setKcAdminToken();
    this.kcAdmin.clients.addMultipleProtocolMappers(
      { id: 'dec215b2-156f-46eb-b437-eed9126e11e6' },
      mapper,
    );
  }

  async getUserList() {
    await this.setKcAdminToken();
    const result = await this.kcAdmin.users.find({ max: 99999 });
    return result;
  }
}

const keycloakConfig: KeycloakRequirement = new KeycloakRequirement(
  KEYCLOAK_HOST,
  KEYCLOAK_REALM,
);

export const kcAdmin = new KeycloakAdmin(keycloakConfig);
